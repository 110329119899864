import Controller from '../../../../frontend/controllers/application_controller'
import StoryCache from '../../../../frontend/models/story_cache'
import { useDebounce, useDispatch } from 'stimulus-use'
import { timingByEnv } from '../../../../frontend/utils'

export default class extends Controller {
  static targets = [
    'form',
    'inputField',
    'submit',
    'errorMessage',
    'errorTemplate',
    'toggleButton',
    'submitPicture',
    'textToSpeechContent'
  ]
  static values = {
    cacheId: String,
    updatedAt: Number,
    wordCountTarget: Object,
    disabled: Boolean,
    persisted: Boolean
  }
  static debounces = [{ name: '#saveStory', wait: timingByEnv(1000) }]

  // Lifecycle
  connect() {
    useDebounce(this)
    useDispatch(this, { eventPrefix: false })

    this.storyCache = new StoryCache(this.inputFieldTarget, this.cacheIdValue, this.handleError)

    if (this.persistedValue) {
      this.storyCache.reset()
    } else {
      this.storyCache.load()
    }

    setTimeout(this.#dispatchWordCount.bind(this), 50)
    this.#changeButtonState()
    this.#changeToggleButtonState()
    this.#togglePictureUpload()
  }

  // Actions
  update() {
    this.#saveStory()
    this.#dispatchWordCount()
    this.#changeButtonState()
    this.#changeToggleButtonState()
    this.#togglePictureUpload()
  }

  submitPhoto() {
    this.formTarget.requestSubmit()
  }

  validateWordCount(e) {
    const wordCount = this.#wordCount

    if (
      (this.wordCountTargetValue.min > 1 && wordCount < this.wordCountTargetValue.min) ||
      (this.wordCountTargetValue.max >= 1 && wordCount > this.wordCountTargetValue.max)
    ) {
      e.preventDefault()
      e.stopImmediatePropagation()
      this.#displayErrorMessage()
    }
  }

  // Private Methods
  #saveStory() {
    this.storyCache.save()
  }

  #changeButtonState() {
    if (!this.hasSubmitTarget || !this.hasInputFieldTarget) return
    if (this.disabledValue) return

    const ttsIcon =
      this.hasTextToSpeechContentTarget && this.textToSpeechContentTarget.querySelector('button')
    const targets = [...this.submitTargets, ttsIcon].filter(el => el !== null && el !== undefined)

    if (this.#wordCount >= 1 && this.kidId) {
      targets.forEach(el => el.removeAttribute('disabled'))
    } else {
      targets.forEach(el => el.setAttribute('disabled', true))
    }
  }

  #changeToggleButtonState() {
    if (!this.hasToggleButtonTarget) return

    if (this.#wordCount >= 1) {
      this.toggleButtonTargets.forEach(toggle => toggle.removeAttribute('disabled', ''))
    } else {
      this.toggleButtonTargets.forEach(toggle => toggle.setAttribute('disabled', ''))
    }
  }

  #togglePictureUpload() {
    if (!this.hasSubmitPictureTarget) return

    if (this.#wordCount >= 1) {
      this.submitPictureTarget.setAttribute('disabled', true)
      this.submitPictureTarget.parentElement.setAttribute('disabled', true)
    } else {
      this.submitPictureTarget.removeAttribute('disabled')
      this.submitPictureTarget.parentElement.removeAttribute('disabled')
    }
  }

  #dispatchWordCount() {
    this.dispatch('writing--component:updated', { wordCount: this.#wordCount })
  }

  #displayErrorMessage() {
    this.errorMessageTarget.innerHTML = ''
    this.errorMessageTarget.append(this.errorTemplateTarget.content.cloneNode(true))

    this.errorMessageTarget.classList.add('opacity-100')
    this.errorMessageTarget.classList.remove('opacity-0')
    this.dispatch('play:sound', { sound: 'gong' })

    setTimeout(() => {
      this.errorMessageTarget.classList.remove('opacity-100')
      this.errorMessageTarget.classList.add('opacity-0')
    }, 4000)
  }

  // Private Getters
  get #wordCount() {
    return (this.inputFieldTarget.value.match(/([A-zÀ-ú]+){2,}/gu) || []).length
  }
}
