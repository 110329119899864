import Controller from '../application_controller'

export default class extends Controller {
  static values = { tags: Array, enforceTags: Boolean, mode: String }

  connect() {
    import(/* webpackChunkName: "tagify" */ '@yaireo/tagify')
      .then(Module => {
        this.Tagify = Module.default
        this.initializeTagify()
      })
      .catch(error => {
        this.handleError(error)
      })
  }

  // private

  initializeTagify() {
    this.tagify = new this.Tagify(this.element, {
      mode: this.mode,
      enforceWhitelist: this.enforceTagsValue,
      originalInputValueFormat: valuesArr => valuesArr.map(item => item.value).join(','),
      whitelist: this.tagsValue,
      dropdown: {
        maxItems: 100
        // enabled: 0 // <- show suggestions on focus
      }
    })
  }

  // getters

  get mode() {
    return this.modeValue || null
  }
}
