import { Controller } from '@hotwired/stimulus'
import { useFieldAutoResize } from '../../../frontend/mixin/useFieldAutoResize'
import { useTemplate } from '../../../frontend/mixin/useTemplate'
import { useDebounce } from 'stimulus-use'

export default class extends Controller {
  static targets = ['textarea', 'field', 'list', 'button']
  static values = { list: Array }
  static debounces = ['parse']

  // Lifecycle
  initialize() {
    import(/* webpackChunkName: "papaparse" */ 'papaparse').then(Module => {
      this.Papa = Module.default
    })

    useTemplate(this, { viewElement: this.listTarget })
    useDebounce(this, { wait: 50 })
    useFieldAutoResize(this, {
      fieldElement: this.textareaTarget,
      scrollRootElement: document.querySelector('[data-scroll-container]')
    })
  }

  // Actions

  parse(event) {
    this.listValue = this.papaParsedList
  }

  removeItem(event) {
    this.listValue = this.listValue.filter((item, index) => index !== event.params.index)
    this.textareaTarget.value = this.listValue.join(', ')
  }

  // Callback
  listValueChanged = value => {
    this.listTarget.innerHTML = this.formatedList
    this.fieldTarget.value = value.join(',')

    // here we render the template with the interpolated values. the format is an array of objects
    this.renderTemplate(value.map((item, index) => ({ text: item, index })))

    this.buttonTarget.classList.toggle('hidden', value.length === 0)
  }

  // Private

  // Getters and Setters

  get parsedList() {
    return this.textTarget.value.split(',').map(item => item.trim())
  }

  get papaParsedList() {
    if (!this.Papa) return

    return this.Papa.parse(this.textareaTarget.value)
      .data.flat()
      .map(item => item.trim()) // and remove empty strings
      .filter(item => item.length > 0)
  }

  get formatedList() {
    return this.listValue.map(item => `<span class="badge badge-info">${item}</span>`).join('')
  }
}
