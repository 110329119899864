import Controller from '../application_controller'
import { getMetaValue } from '../../utils'

export default class extends Controller {
  static get shouldLoad() {
    return getMetaValue('google_tag_manager')
  }

  static values = { eventContent: Object }

  connect() {
    if (this.isPreview) return

    // this is to handle events send from the backend
    setTimeout(() => {
      if (window.dataLayer && this.hasEventContentValue) {
        dataLayer.push(this.eventContentValue)
      }
    }, 500)
  }

  disconnect() {
    // just make sure the flash is not persisted during a Turbo navigation
    this.element.remove
  }

  // actions

  trackEvent({ params }) {
    if (window.dataLayer) {
      dataLayer.push(params.eventContent)
    }
  }

  signUpNew(e) {
    //   if (typeof gtag === 'function') {
    //     gtag('event', 'new', {
    //       event_category: 'registrations'
    //     })
    //   }
  }

  visitSubscriptionPage(e) {
    //   if (typeof gtag === 'function') {
    //     gtag({
    //       event: 'product_details_view',
    //       event_category: 'true',
    //       products: [
    //         {
    //           product_category: 'sub_parent'
    //         }
    //       ]
    //     })
    //   }
  }

  subscriptionInitiate() {
    //   if (typeof gtag === 'function') {
    //     gtag('event', 'initiate', {
    //       event_category: 'stripe_subscriptions'
    //     })
    //   }
  }

  chargeInitiate() {
    //   if (typeof gtag === 'function') {
    //     gtag('event', 'initiate', {
    //       event_category: 'stripe_charges'
    //     })
    //   }
  }
}
