import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['linkTag']

  selectPlan(event) {
    let target = event.target
    let radio = target.parentElement.querySelector('.plan__card__radio')

    this.resetRadioButtons()
    radio.classList.add('active')
    this.linkTagTarget.href = target.dataset.link
  }

  resetRadioButtons() {
    document.querySelectorAll('.plan__card__radio').forEach(link => {
      link.classList.remove('active')
    })
  }
}
