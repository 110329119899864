import Controller from '../application_controller'

export default class extends Controller {
  static metaNames = [
    'user_account_type',
    'first_name',
    'last_name',
    'email',
    'user_id',
    'admin',
    'ios_app',
    'subscribed'
  ]

  static values = {
    enable: Boolean,
    appId: String
  }

  connect() {
    // https://gist.github.com/FokkeZB/033d4a9089fff392f7990a452e1d323d#gistcomment-1910255
    // there could be a race condition

    const timeout = setTimeout(() => clearInterval(interval), 30000)
    const interval = setInterval(() => {
      if (window.Intercom?.booted) {
        try {
          window.Intercom('update', {
            hide_default_launcher: !this.enableValue,
            ...this.intercomMeta
          })
        } catch (error) {
          console.error(error)
        }

        requestAnimationFrame(() => {
          this.toggleIntercom()
        })

        clearInterval(interval)
        clearTimeout(timeout)
      }
    }, 100)
  }

  // private

  hideMessenger() {
    // this was creating a bug in CI where a messenger window would appear
    // by safety we hide it as this means it could appear on kids pages too
    // and we need to wait more than just a few seconds for Intercom to boot

    if (this.enableValue) return

    const timeout = setTimeout(() => clearInterval(interval), 30000)
    const interval = setInterval(() => {
      if (this.intercomContainer) {
        requestAnimationFrame(() => {
          this.toggleIntercom()
        })

        clearInterval(interval)
        clearTimeout(timeout)
      }
    }, 100)
  }

  hideIntercom() {
    try {
      window.Intercom('update', {
        hide_default_launcher: true
      })
    } catch (error) {
      console.error(error)
    }
  }

  showIntercom() {
    try {
      window.Intercom('update', {
        hide_default_launcher: false
      })
    } catch (error) {
      console.error(error)
    }
  }

  toggleIntercom() {
    if (this.enableValue) {
      this.showIntercom()
    } else {
      this.hideIntercom()
    }
  }
  // getters

  get intercomContainer() {
    return document.getElementById('intercom-container')
  }

  get intercomApp() {
    return document.querySelector('.intercom-lightweight-app')
  }

  get intercomMeta() {
    return {
      user_id: this.userId,
      email: this.email,
      first_name: this.firstName,
      last_name: this.lastName,
      subscribed: this.subscribed,
      admin: this.admin,
      user_account_type: this.userAccountType
    }
  }
}
