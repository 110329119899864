import { Controller } from '@hotwired/stimulus'
import { useMutation, useTransition } from 'stimulus-use'
import { CountUp } from 'countup.js'

export default class extends Controller {
  static values = { goal: Number, count: Number }
  static targets = [
    'video',
    'progressBar',
    'observable',
    'visibleCount',
    'count',
    'lastWritingText'
  ]

  initialize() {
    useMutation(this, {
      characterData: true,
      childList: true,
      subtree: true,
      element: this.observableTarget
    })

    if (this.hasLastWritingTextTarget) {
      useTransition(this, {
        element: this.lastWritingTextTarget,
        enterFrom: 'opacity-0 scale-25',
        enterTo: 'opacity-100 -translate-y-12 scale-100',
        leaveFrom: 'opacity-100 -translate-y-12 scale-100',
        leaveTo: 'opacity-0 -translate-y-12 scale-100',
        removeToClasses: false,
        leaveAfter: 500
      })
    }
  }

  // actions
  run() {
    this.animationController.play()
    setTimeout(() => {
      this.animationController.pause()
    }, 1500)
  }

  // callbacks
  mutate() {
    if (this.hasCountTarget) {
      this.lastWritingText = this.countTarget.dataset.lastWritingText
      this.animateCounter()
    }
  }

  // private
  animateCounter() {
    this.animationController.play()

    this.progressBarTarget.style.width = `${this.progress}%`
    const countUp = new CountUp(this.visibleCountTarget, this.counterValue, {
      startVal: this.counterOldValue,
      separator: ' ',
      duration: 10
    })
    countUp.start(() => {
      this.animationController.pause()
    })
  }

  // getters  & setters

  set lastWritingText(text) {
    if (!this.hasLastWritingTextTarget) return

    this.lastWritingTextTarget.textContent = text
    this.enter()
  }

  get counterOldValue() {
    return parseInt(this.visibleCountTarget.textContent.replace(' ', ''))
  }

  get counterValue() {
    return parseInt(this.countTarget.textContent.replace(' ', ''))
  }

  get progress() {
    return (this.counterValue / this.goalValue) * 100
  }

  get animationController() {
    return this.videoTarget.firstElementChild.lottieController
  }
}
