import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
  static targets = ['submit']

  toggleSubmit(e) {
    this.submitTarget.disabled = !e.currentTarget.checked
    this.submitTarget.classList.toggle('disabled', !e.currentTarget.checked)
    if (e.currentTarget.checked) {
      this.submitTarget.removeAttribute('tooltip')
    } else {
      this.submitTarget.setAttribute(
        'tooltip',
        'Vous devez accepter les conditions générales de vente'
      )
    }
  }

  submit() {
    requestAnimationFrame(() => window.Rails.fire(this.element, 'submit'))
  }

  requestSubmit() {
    this.element.requestSubmit()
  }

  visit(event) {
    if (event.detail.success) {
      Turbo.visit(event.detail.fetchResponse.response.url)
    }
  }
}
