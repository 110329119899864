import { Controller } from '@hotwired/stimulus'
import { useDispatch } from 'stimulus-use'

import confetti from 'canvas-confetti'
import { isTestEnvironement } from '../../../../frontend/utils'

const sleep = async duration => {
  await new Promise(f => setTimeout(f, duration))
}

export default class extends Controller {
  static values = { url: String }

  async connect() {
    useDispatch(this, { eventPrefix: false })

    this.dispatch('writing:completed')

    if (this.element.classList.contains('animation-request')) await this.animateCompletion()
    this.element.classList.add('writing-section--active')
  }

  disconnect() {
    try {
      confetti.reset()
    } catch (error) {}
    this.element.remove()
  }

  async animateCompletion() {
    this.element.classList.remove('animation-request')
    if (isTestEnvironement) return

    await sleep(5)
    this.confetti()
  }

  confetti() {
    function r(min, max) {
      return Math.random() * (max - min) + min
    }

    confetti({
      angle: r(55, 125),
      spread: r(50, 70),
      particleCount: r(50, 100),
      origin: {
        y: 0.6
      }
    })

    this.dispatch('play:sound', { sound: 'success' })
  }
}
