import Controller from '../application_controller'

// This controller is used to auto-click elements on page load
// the wait params is used to delay the click
// the match-url-selector param is used to only click the element if the current url matches the id of the element
// example :
// <div data-controller="auto-click" data-auto-click-wait-value="1000" data-auto-click-match-url-selector-value="true" id="my-element-id"></div>
// visiting this url fr.plume-app.co?click=my-element-id
// will click the element after 1 second
//
export default class extends Controller {
  static values = {
    wait: { type: Number, default: 0 },
    matchUrlSelector: { type: Boolean, default: false }
  }

  connect() {
    if (this.isPreview) return

    if (!this.matchUrlSelectorValue) {
      // we always click the element if the match-url-selector-value is false (default value)
      this.#clickElement()
    } else if (this.matchUrlSelectorValue && this.urlSelector === this.element.id) {
      // we click the element only if the match-url-selector-value is true and the url selector matches the element id
      this.#clickElement()

      // remove the click params from the url
      const url = new URL(window.location.href)
      url.searchParams.delete('click')
      window.history.replaceState({}, '', url)
    }
  }

  private

  #clickElement() {
    setTimeout(() => {
      this.element.click()
    }, this.waitValue)
  }

  // getters

  get urlSelector() {
    return new URLSearchParams(window.location.search).get('click')
  }
}
