import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['newAttributes', 'form', 'submit', 'field']

  updateVisibility(event) {
    if (event.target.value === '') {
      this.newAttributesTarget.classList.remove('hidden')
      this.fieldTargets.forEach(field => (field.required = true))
      this.formTarget.dataset.controller = 'validation'
    } else {
      this.newAttributesTarget.classList.add('hidden')
      this.fieldTargets.forEach(field => (field.required = false))
      delete this.formTarget.dataset.controller
    }
  }
}
