import Swal from 'sweetalert2'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['displayed']

  initialize() {
    if (this.message !== '' && !this.displayed) {
      this.renderToast().then(() => {
        this.displayedTarget.value = true
      })
    }
  }

  renderToast() {
    const Toast = Swal.mixin({
      toast: true,
      position: this.position,
      showConfirmButton: this.confirm,
      timer: this.timer
    })

    return Toast.fire({
      icon: this.type,
      title: this.message
    })
  }

  get timer() {
    return parseInt(this.data.get('timer')) || 3000
  }

  get position() {
    return this.data.get('position') || 'bottom-end'
  }

  get type() {
    return this.data.get('type') || 'success'
  }

  get message() {
    return this.data.get('message') || ''
  }

  get confirm() {
    return this.data.get('confirm') === 'true'
  }

  get displayed() {
    return this.displayedTarget.value === 'true'
  }
}
