import Controller from '../application_controller'

export default class extends Controller {
  static targets = ['field', 'suggestion']
  static values = { locale: String }

  connect() {
    import(/* webpackChunkName: "mailcheck" */ 'mailcheck')
      .then(module => {
        this.mailcheck = module
        this.loaded = true
      })
      .catch(error => {
        this.handleError(error)
      })
  }

  replace(e) {
    e.preventDefault()
    this.fieldTarget.value = this.suggestion
    this.clearSuggestion()
  }

  suggest(e) {
    if (!this.loaded) return

    this.mailcheck.run({
      email: e.currentTarget.value,
      suggested: this.displaySuggestion,
      empty: this.clearSuggestion
    })
  }

  displaySuggestion = suggestion => {
    this.suggestionTarget.textContent = this.suggestionByLocale(suggestion)
    this.suggestion = suggestion.full
    this.suggestionTarget.classList.add('pointer')
  }
  clearSuggestion = () => {
    this.suggestionTarget.textContent = ' '
    this.suggestion = ''
    this.suggestionTarget.classList.remove('pointer')
  }

  suggestionByLocale = suggestion => {
    return this.localeValue == 'fr'
      ? `Voulez vous dire ${suggestion.full} ?`
      : `Did you mean ${suggestion.full}?`
  }
}
