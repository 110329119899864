import { Controller } from '@hotwired/stimulus'
import { getMetaValue } from '../../utils'

export default class extends Controller {
  static get shouldLoad() {
    return getMetaValue('facebook_pixel')
  }

  connect() {
    setTimeout(() => {
      if (this.type && this.value && this.eventTracked != true) {
        fbq('track', this.type, {
          value: this.value,
          currency: 'EUR',
          contents: [
            {
              id: this.intentId
            }
          ]
        })
      }
    }, 1500)
  }

  newLead() {
    if (this.hasFbq) {
      fbq('track', 'Lead')
    }
  }

  viewContent(e) {
    if (this.hasFbq) {
      fbq('track', 'ViewContent')
    }
  }

  get type() {
    return this.data.get('type')
  }

  get value() {
    return this.data.get('value') || '0'
  }

  get intentId() {
    return this.data.get('intentId')
  }

  get eventTracked() {
    return this.data.get('eventTracked')
  }

  get hasFbq() {
    try {
      return !!fbq
    } catch (error) {
      return false
    }
  }
}
