import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'

const application = Application.start()

// remove Stimulus depreciation warnings in production
if (process.env.NODE_ENV === 'production') {
  application.logger = { ...application.logger, warn: () => {} }
}

const context = require.context('../controllers/application', true, /\.js$/)
const sharedContext = require.context('../controllers/shared', true, /\.js$/)
const nathanContext = require.context('../controllers/nathan', true, /\.js$/)
const componentContext = require.context('../../components/', true, /(.*)\/.+\.js$/)
const definitions = [
  ...definitionsFromContext(context),
  ...definitionsFromContext(sharedContext),
  ...definitionsFromContext(nathanContext),
  ...definitionsFromContext(componentContext)
]

definitions.forEach(({ identifier, controllerConstructor }) => {
  application.register(identifier.replace(/^-/, ''), controllerConstructor)
})

requestAnimationFrame(() => {
  document.documentElement.setAttribute('stimulus-connected', '')
})

// New bits
const defaultErrorHandler = application.handleError.bind(application)

const sentryErrorHandler = (error, message, detail = {}) => {
  defaultErrorHandler(error, message, detail)
  Sentry.captureException(error, { message, ...detail })
}

application.handleError = sentryErrorHandler

application.stimulusUseDebug = process.env.NODE_ENV === 'development'
application.debug = false
