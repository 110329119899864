import { useIntersection } from 'stimulus-use'
import Controller from '../application_controller'

export default class extends Controller {
  initialize() {
    if (this.isPreview) return

    useIntersection(this)
  }

  appear() {
    window.setTimeout(() => {
      this.element.classList.add(this.className)
    }, this.delay + this.offset)
  }

  get className() {
    return this.data.has('className') ? this.data.get('className') : ''
  }

  get delay() {
    return this.data.has('delay') ? parseInt(this.data.get('delay')) : 100
  }

  get offset() {
    return this.data.has('offset') ? parseInt(this.data.get('offset')) : 0
  }
}
