import './turbo_custom_stream_actions'

//The Turbo instance is automatically assigned to window.Turbo upon import:
if (!window.Turbo) {
  // ensure we only load turbo once
  require('@hotwired/turbo-rails')
}

document.addEventListener('turbo:before-cache', () => {
  // Manually tear down bootstrap modals before caching. If Turbo
  // caches the modal then tries to restore it, it breaks bootstrap's JS.
  // We can't just use bootstrap's `modal('close')` method because it is async.
  // Turbo will cache the page before it finishes running.
  if (document.body.classList.contains('modal-open')) {
    $('.modal').hide().removeAttr('aria-modal').attr('aria-hidden', 'true')
    $('.modal-backdrop').remove()
    $('body').removeClass('modal-open')
  }

  // remove remote modals before caching the page to avoid navigating back to an open modal
  const modalFrame = document.querySelector('turbo-frame#modal')
  if (modalFrame) {
    modalFrame.innerHTML = ''
  }
})

document.addEventListener('turbo:load', () => {
  // Set a meta tag to indicate that the page has been loaded with Turbo.
  // This is useful for intergration test to ensure that everiting is loaded before stating to interact with the page
  document.documentElement.setAttribute('turbo-loaded', '')
})

// https://github.com/hotwired/turbo/pull/863#issuecomment-1470184953
document.addEventListener('turbo:frame-missing', function (event) {
  // TODO: Report this event somewhere...
  Sentry.captureMessage('Turbo frame missing', { detail: event.detail })
  console.log('Turbo frame missing', event.detail.response)

  // Visit the response
  event.preventDefault()
  event.detail.visit(event.detail.response.url)
})
