import { Controller } from '@hotwired/stimulus'
import { timingByEnv } from '../../../frontend/utils'

export default class extends Controller {
  static targets = ['summary', 'content']
  static values = {
    isAccordion: Boolean
  }
  duration = timingByEnv(300)

  connect() {
    // Store the animation object (so we can cancel it if needed)
    this.animation = null
    // Store if the element is closing
    this.isClosing = false
    // Store if the element is expanding
    this.isExpanding = false
  }

  // Actions

  onClick(e) {
    // Stop default behaviour from the browser
    e.preventDefault()
    // Add an overflow on the <details> to avoid content overflowing
    this.element.style.overflow = 'hidden'
    // Check if the element is being closed or is already closed
    if (this.isClosing || !this.element.open) {
      this.open()
      // Check if the element is being openned or is already open
    } else if (this.isExpanding || this.element.open) {
      this.close()
    }
  }

  closeOtherContent() {
    if (this.isAccordionValue) {
      this.sibilings.forEach(sibilingController => {
        sibilingController.element.style.overflow = 'hidden'
        sibilingController.close()
      })
    }
  }

  open() {
    // Apply a fixed height on the element
    this.element.style.height = `${this.element.offsetHeight}px`
    // Force the [open] attribute on the details element
    this.element.open = true
    // Wait for the next frame to call the expand function
    window.requestAnimationFrame(() => this.#expand())
  }

  close() {
    if (this.isExpanding || this.element.open) {
      // Wait for the next frame to call the expand function
      window.requestAnimationFrame(() => this.#shrink())
    }
  }

  // Private methods

  #shrink() {
    // Set the element as "being closed"
    this.isClosing = true

    // Store the current height of the element
    const startHeight = `${this.element.offsetHeight}px`
    // Calculate the height of the summary
    const endHeight = `${this.summaryTarget.offsetHeight}px`

    // If there is already an animation running
    if (this.animation) {
      // Cancel the current animation
      this.animation.cancel()
    }

    // Start a WAAPI animation
    this.animation = this.element.animate(
      {
        // Set the keyframes from the startHeight to endHeight
        height: [startHeight, endHeight]
      },
      {
        duration: this.duration
      }
    )
    this.summaryTarget.classList.remove('expanded')

    // When the animation is complete, call #onAnimationFinish()
    this.animation.onfinish = () => this.#onAnimationFinish(false)
    // If the animation is cancelled, isClosing variable is set to false
    this.animation.oncancel = () => (this.isClosing = false)
  }

  #expand() {
    // Set the element as "being expanding"
    this.isExpanding = true
    // Get the current fixed height of the element
    const startHeight = `${this.element.offsetHeight}px`
    // Calculate the open height of the element (summary height + content height)
    const endHeight = `${this.summaryTarget.offsetHeight + this.contentTarget.offsetHeight}px`

    // If there is already an animation running
    if (this.animation) {
      // Cancel the current animation
      this.animation.cancel()
    }

    // Start a WAAPI animation
    this.animation = this.element.animate(
      {
        // Set the keyframes from the startHeight to endHeight
        height: [startHeight, endHeight]
      },
      {
        duration: this.duration
      }
    )
    this.summaryTarget.classList.add('expanded')
    // When the animation is complete, call #onAnimationFinish()
    this.animation.onfinish = () => this.#onAnimationFinish(true)
    // If the animation is cancelled, isExpanding variable is set to false
    this.animation.oncancel = () => (this.isExpanding = false)
  }

  #onAnimationFinish(open) {
    // Set the open attribute based on the parameter
    this.element.open = open
    // Clear the stored animation
    this.animation = null
    // Reset isClosing & isExpanding
    this.isClosing = false
    this.isExpanding = false
    // Remove the overflow hidden and the fixed height
    this.element.style.height = this.element.style.overflow = ''
  }

  // getters

  get sibilings() {
    const parentElement = this.element.parentElement

    return this.application.controllers.filter(
      controller =>
        controller.identifier == this.identifier &&
        controller != this &&
        controller.element.parentElement == parentElement
    )
  }
}
