import { Controller } from '@hotwired/stimulus'
import { FlipDown } from '../../vendor/flipdown'

export default class extends Controller {
  static values = { datetime: Number }

  connect() {
    this.startCountdown()
  }

  private

  startCountdown() {
    const currentTimeInSeconds = Date.now() / 1000
    if (!this.datetimeValue || this.datetimeValue < currentTimeInSeconds) {
      return
    }

    new FlipDown(this.datetimeValue, {
      theme: 'light',
      headings: ['jours', 'heures', 'minutes', 'secondes']
    }).start()
  }
}
