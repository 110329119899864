import { BasePlugin } from '@shopify/draggable'

const onSortableSort = Symbol('onSortableSort')

export class ExludedClasses extends BasePlugin {
  attach() {
    this.draggable.on('sortable:sort', this[onSortableSort])
  }

  detach() {}

  [onSortableSort](event) {
    const { overContainer } = event.dragEvent.data
    const excludedClasses = overContainer.dataset.exludedDraggableItemsClasses
    if (excludedClasses) {
      Array.from(excludedClasses.split(' ')).forEach(className => {
        if (event.data.source.classList.contains(className)) {
          event.cancel()
        }
      })
    }
  }
}
