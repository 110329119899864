import ApplicationController from '../application_controller'
import { useResize, useDebounce } from 'stimulus-use'
import { fabric } from 'fabric'
import { updateZone } from '../../api'
import { timingByEnv } from '../../utils'

const defaultText = 'Votre texte ici'

export default class extends ApplicationController {
  static targets = [
    'canvas',
    'image',
    'canvasContainer',
    'textZonesInput',
    'form',
    'saving',
    'saved',
    'remaining'
  ]
  static debounces = ['textChangedHandler', 'saved']

  textBoxes = new Set()

  connect() {
    const [observe, unobserve] = useResize(this)
    Object.assign(this, { observe, unobserve })
    this.unobserve()
    useDebounce(this, { wait: timingByEnv(1000) })
    this.canvas = new fabric.Canvas(this.canvasTarget)
    this.originalCanvasWidth = this.canvas.getWidth()
    this.originalCanvasHeight = this.canvas.getHeight()
    this.canvas.on('object:modified', this.modifiedHandler)
    this.canvas.on('text:changed', this.textChangedHandler)
    this.canvas.on('text:editing:entered', this.textEnteredHandler)
    this.canvas.on('text:editing:exited', this.textExitedHandler)

    this.loadImage()
    this.updateCompletion()
  }

  disconnect() {
    this.element.remove()
  }

  loadImage() {
    if (this.image) return

    new fabric.Image.fromURL(this.imagePath, image => {
      this.observe()
      this.fadeIn()
      this.image = image
      requestAnimationFrame(() => {
        this.drawBackground()
      })
    })
  }

  drawBackground = () => {
    this.canvas.setBackgroundImage(this.image, this.addTextZones.bind(this), {
      scaleX: this.originalCanvasWidth / this.image.width,
      scaleY: this.originalCanvasHeight / this.image.height
    })
  }

  textChangedHandler = e => {
    this.textZonesInputTarget.value = JSON.stringify(this.textBoxesData)
    this.saving()
    requestAnimationFrame(() => {
      window.Rails.fire(this.formTarget, 'submit')
    })
  }

  textEnteredHandler = e => {
    if (e.target.text === defaultText) {
      e.target.text = ''
      e.target.hiddenTextarea.value = ''

      this.canvas.renderAll()
    }
  }

  textExitedHandler = e => {
    if (e.target.text === '') {
      e.target.text = defaultText
      this.canvas.renderAll()
    }
  }

  modifiedHandler = e => {
    if (!this.admin) return
    const { id, width, left, top } = e.target
    updateZone(id, { width, left, top })
  }

  saving() {
    // saving ON
    this.savingTarget.classList.remove('hidden')
    this.savingTarget.classList.add('flex')

    // saved OFF
    this.savedTarget.classList.add('hidden')
    this.savedTarget.classList.remove('flex')
  }

  saved() {
    // saved ON
    this.savedTarget.classList.remove('hidden')
    this.savedTarget.classList.add('flex')

    // saving OFF
    this.savingTarget.classList.add('hidden')
    this.savingTarget.classList.remove('flex')
    this.updateCompletion()
  }

  unSaved() {
    // saved OFF
    this.savedTarget.classList.add('hidden')
    this.savedTarget.classList.remove('flex')

    // saving OFF
    this.savingTarget.classList.add('hidden')
    this.savingTarget.classList.remove('flex')
  }

  updateCompletion() {}

  resize({ width, height }) {
    this.containerWidth = width
    this.containerHeight = height
    this.containerRatio = width / height
    const scaleRatio = Math.min(
      this.containerWidth / this.originalCanvasWidth,
      this.containerHeight / this.originalCanvasHeight
    )
    requestAnimationFrame(() => {
      if (width / this.imageRatio < this.containerHeight) {
        this.canvas.setDimensions({ width, height: width / this.imageRatio })
      } else {
        this.canvas.setDimensions({ width: height * this.imageRatio, height })
      }
      this.canvas.setZoom(scaleRatio)
    })
  }

  addTextZones() {
    Object.keys(this.textZones).forEach((textZoneId, index) => {
      const zoneWriting = this.writings[parseInt(textZoneId)] || defaultText
      const text = new LimitedTextbox(zoneWriting, {
        maxwidth: this.textZones[textZoneId].width,
        ...this.textZones[textZoneId],
        ...this.textDefaults
      })
      text.drawBorders(this.canvas.getContext())
      this.canvas.add(text)
      this.textBoxes.add(text)
    })
  }

  fadeIn() {
    this.element.classList.remove('opacity-0')
    this.element.classList.add('opacity-100')
  }

  //getters
  get updatePath() {
    return this.data.has('updatePath') && this.data.get('updatePath')
  }

  get createWritingPath() {
    return this.data.has('createWritingPath') && this.data.get('createWritingPath')
  }

  get imageLoaded() {
    return this.imageTarget.complete
  }

  get writings() {
    try {
      return (this.data.has('writings') && JSON.parse(this.data.get('writings'))) || []
    } catch (error) {
      return []
    }
  }

  get textDefaults() {
    return {
      fontSize: 18,
      fontFamily: 'Poppins',
      borderColor: 'gray',
      borderScaleFactor: 3,
      padding: 5,
      lockMovementY: !this.admin,
      lockMovementX: !this.admin,
      hasControls: this.admin
    }
  }

  get textZones() {
    return JSON.parse(this.data.get('textZones'))
  }

  get completedTextBoxes() {
    return [...this.textBoxes].filter(({ text }) => {
      return !!text && text !== defaultText
    })
  }

  get textBoxesData() {
    return [...this.textBoxes].reduce((object, { id, text }) => {
      const textToSave = text === defaultText ? '' : text
      return { ...object, [id]: textToSave }
    }, {})
  }

  get imageWidth() {
    return (this.image && this.image.width) || 0
  }

  get imageHeight() {
    return (this.image && this.image.height) || 0
  }

  get imageRatio() {
    return this.imageHeight > 0 ? this.imageWidth / this.imageHeight : 0
  }

  get imagePath() {
    return this.data.get('imagePath')
  }
}

const LimitedTextbox = fabric.util.createClass(fabric.Textbox, {
  // Override `insertChars` method
  insertChars: function (chars) {
    // if (this.maxLines) {
    //   const newLinesLength = this._wrapText(this.ctx, this.text + chars).length
    //   if (newLinesLength > this.maxLines) {
    //     return
    //   }
    // }
    this.callSuper('insertChars', chars)
  },

  onKeyDown: function (e) {
    const { key } = e
    if (
      key !== 'Backspace' &&
      key !== 'ArrowLeft' &&
      key !== 'ArrowRight' &&
      key !== 'ArrowUp' &&
      key !== 'ArrowDown'
    ) {
      if (this._textLines.length > this.maxLines) {
        e.preventDefault()
        return
      }
    }

    if (key === 'Enter') {
      if (this._textLines.length === this.maxLines) {
        e.preventDefault()
        return
      }
    }
    this.callSuper('onKeyDown', e)
  },
  onKeyUp: function (e) {
    if (this._textLines.length > this.maxLines) {
      const position = e.target.selectionStart - 1
      const undoText =
        e.target.value.substring(0, position) + e.target.value.substring(position + 1)
      this.text = undoText
      this.hiddenTextarea.value = undoText
      this.hiddenTextarea.setSelectionRange(position, position)
      requestAnimationFrame(() => {
        this.canvas.renderAll()
      })
      return
    }
    this.callSuper('onKeyUp', e)
  }
})
