import '../vendor/polyfills'

window.Rails = require('@rails/ujs')
window.Rails = Rails

import '../vendor/bootstrap'
import '../vendor/sentry'
import '../vendor/turbo'
import '../components/confirmation'

import '../vendor/stimulus_nathan'

// start UJS
window.Rails.start()

requestAnimationFrame(() => {
  document.documentElement.setAttribute('js-loaded', 'true')
})
