import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

export default class extends Controller {
  static targets = ['select']
  static values = { create: Boolean, optionCreate: String, noResults: String, multiple: Boolean }

  connect() {
    new TomSelect(this.selectTarget, {
      plugins: this.plugins,
      persist: false,
      createOnBlur: this.createValue,
      create: this.createValue,
      hidePlaceholder: true,
      render: {
        option_create: this.optionCreateTemplate,
        no_results: this.noResultsTemplate
      },
      onInitialize: function () {
        this.wrapper.classList.remove('invisible')
      }
    })
  }

  optionCreateTemplate = (data, escape) => {
    return `<div class="create">${this.optionCreateValue} <strong>${escape(
      data.input
    )}</strong>&hellip;</div>`
  }

  noResultsTemplate = (data, escape) => {
    return `<div class="no-results">${this.noResultsValue}</div>`
  }

  get plugins() {
    const plugins = ['input_autogrow']

    if (this.multipleValue) {
      plugins.push('remove_button')
      plugins.push('checkbox_options')
    }

    return plugins
  }
}
