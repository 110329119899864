// Add your own Custom Turbo StreamActions
// Define Rails helpers in `app/helpers/turbo_stream_actions_helper.rb`
//
// These actions run in the context of a turbo-stream element. You have access to methods like the following:
//
//   this.action - The action attribute value (for example: "append")
//   this.target - The target attribute value (the target element ID)
//   this.targetElements - An array of target elements the template will be rendered to
//   this.templateContent - The contents of the main `<template>`
//
// Source code for the stream element can be found here:
// https://github.com/hotwired/turbo/blob/main/src/elements/stream_element.ts

import { StreamActions } from '@hotwired/turbo'
import morphdom from 'morphdom'

// Set dataset attributes on target elements
// <%= turbo_stream.set_dataset_attribute, target: "my-id", key: "id", value: "1" %>
StreamActions.set_dataset_attribute = function () {
  const value = this.getAttribute('value')
  const key = this.getAttribute('key')

  this.targetElements.forEach(element => {
    element.setAttribute(`data-${key}`, value)
  })
}

// Set dataset attributes on target elements
// <%= turbo_stream.set_dataset_attribute, target: "my-id", key: "id", value: "1" %>
StreamActions.set_attribute = function () {
  const value = this.getAttribute('value')
  const key = this.getAttribute('key')

  this.targetElements.forEach(element => {
    element.setAttribute(key, value)
  })
}

StreamActions.morph = function () {
  const options = {
    childrenOnly: this.hasAttribute('children-only')
  }

  this.targetElements.forEach(element => {
    morphdom(
      element,
      options.childrenOnly ? this.templateContent : this.templateElement.innerHTML,
      options
    )
  })
}
