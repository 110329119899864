import Controller from '../../../frontend/controllers/application_controller'
import { useDispatch, useMutation, useTransition, useIntersection } from 'stimulus-use'
import { timingByEnv } from '../../../frontend/utils'

export default class extends Controller {
  static targets = ['bubble', 'template', 'content', 'spinner']
  static values = {
    open: { type: Boolean, default: true },
    delay: { type: Number, default: timingByEnv(0) }
  }

  connect() {
    if (this.isPreview) return

    useIntersection(this)
    useDispatch(this, { eventPrefix: false })

    // we listen for mutation of teh bubble to ensure we open the bubble when a new content arrives
    useMutation(this, { element: this.bubbleTarget, childList: true, subtree: true })

    useTransition(this, {
      element: this.bubbleTarget,
      enterActive: 'transition ease-in duration-300',
      enterFrom: 'opacity-0 scale-75',
      enterTo: 'opacity-100 scale-100',
      leaveActive: 'transition ease-in-out duration-500',
      leaveFrom: 'opacity-100 scale-100',
      leaveTo: 'opacity-0 scale-75',
      removeToClasses: false,
      hiddenClass: 'hidden'
    })
  }

  disconnect() {
    this.hasBubbleTarget && this.bubbleTarget.remove()
  }

  // actions
  toggle() {
    this.openValue = !this.openValue

    if (this.openValue) {
      this.#handleBubbleAppear()
    } else {
      this.leave()
    }
  }

  close() {
    this.openValue = false
    this.leave()
  }

  waitForContent() {
    const spinner = document.importNode(this.templateTarget.content, true)
    this.contentTarget.innerText = ''
    this.contentTarget.appendChild(spinner)
  }

  // callbacks

  appear() {
    if (!this.hasBubbleTarget || !this.openValue) return

    this.#handleBubbleAppear()
  }

  mutate() {
    this.openValue = true
    this.#handleBubbleAppear()
  }

  // private

  #handleBubbleAppear = () => {
    // dont show the bubble when there is nothing inside
    if (this.#bubbleContent.length === 0) return

    const { sound, wait, volume } = this.contentTarget.dataset

    let new_wait = this.bubbleTarget.dataset.firstOpen == 'true' ? wait : 50
    this.bubbleTarget.dataset.firstOpen = 'false'

    setTimeout(() => {
      this.enter()
      this.#play(sound, volume)
    }, new_wait)
  }

  #play(sound, volume = '0.5') {
    if (!sound) return

    this.dispatch('play:sound', { sound, volume })
  }

  get #bubbleContent() {
    return this.hasContentTarget ? this.contentTarget.innerText.trim() : ''
  }
}
