import { Controller } from '@hotwired/stimulus'
import { destroy } from '@rails/request.js'
import { timingByEnv } from '../../frontend/utils'

export default class extends Controller {
  static targets = ['modalContainer', 'stack', 'notification']
  popupDelay = timingByEnv(800)

  initialize() {
    this.#allowPause()
  }

  connect() {
    requestAnimationFrame(() => {
      this.ready = true
      this.#popNextNotification()
    })
  }

  // actions

  async markAsRead(event) {
    event.preventDefault()
    const href = event.currentTarget?.href
    this.modalContainerTarget.controller.hide()
    await this.#markAsRead(this.activeNotification)

    Turbo.visit(href)
  }

  dismissNotificationThenFollowLink(event) {
    event.preventDefault()
    const href = event.currentTarget?.href
    this.modalContainerTarget.controller.hide()

    setTimeout(() => {
      Turbo.visit(href)
    }, 500)
  }

  // callbacks
  notificationTargetConnected(notification) {
    const notificationExistAlready =
      notification.parentElement.querySelectorAll(
        `[data-notification-id="${notification.dataset.notificationId}"]`
      ).length > 1

    // in some cases the notification seems to be brocasted twice. Here we apply a filter to unsure notification are only shown once
    if (notificationExistAlready) {
      notification.remove()
    }
    if (!this.ready) return
    if (notification.parentNode !== this.stackTarget) return

    this.#popNextNotification()
  }

  notificationTargetDisconnected(notification) {
    this.#popNextNotification()
  }

  pauseNotifications() {
    this.paused = true
  }

  resumeNotifications() {
    this.paused = false

    this.#popNextNotification()
  }

  // private
  #allowPause() {
    this.paused = false
    this.element.dataset.action = `pause:notifications@document->${this.identifier}#pauseNotifications resume:notifications@document->${this.identifier}#resumeNotifications`
  }

  #popNextNotification() {
    setTimeout(() => {
      if (this.paused) return

      // The notification is a hidden link in the notification stack. This triggers a remote modal
      if (this.activeNotification) {
        this.activeNotification?.click()
        this.paused = true
      }
    }, this.popupDelay)
  }

  async #markAsRead(notification) {
    if (notification.dataset.readUrl.includes('dummy_notification_id')) return

    await destroy(notification.dataset.readUrl)
  }

  get #dismissable() {
    return this.activeNotification?.dataset?.dismissable === 'true'
  }

  get unreadNotifications() {
    if (this.hasNotificationTarget) {
      return this.notificationTargets.filter(notification => notification.dataset.read !== 'true')
    } else {
      return []
    }
  }

  get activeNotification() {
    return this.unreadNotifications[0]
  }
}
