import { Controller } from '@hotwired/stimulus'
import { useMutation } from 'stimulus-use'

export default class extends Controller {
  static values = {
    tooltip: Boolean,
    tooltipText: String,
    tooltipMode: String,
    tooltipTheme: String,
    tooltipDelay: { type: Number, default: 0 }
  }

  connect() {
    if (
      this.tooltipValue &&
      (this.tooltipModeValue == 'disabled_only' || this.tooltipModeValue == 'default')
    ) {
      console.log('init')
      this.#initializeTooltip()
    }
  }

  disconnect() {
    this.tooltip?.destroy()
  }

  // actions

  toogleBusy() {
    console.log('busy')
    this.element.toogleAttribute('busy', !this.element.hasAttribute('busy'))
  }

  // callback

  mutate() {
    this.#toggleTooltip()
  }

  // private

  #initializeTooltip() {
    import(/* webpackChunkName: "tippy.js" */ 'tippy.js').then(Module => {
      this.TippyConstructor = Module.default
      this.tooltip = new this.TippyConstructor(this.element, { ...this.tippyOptions })
      this.#toggleTooltip()

      useMutation(this, {
        element: this.button,
        childList: false,
        subtree: false,
        attributes: true
      })
    })
  }

  #toggleTooltip() {
    if (this.tooltipModeValue == 'disabled_only') {
      this.button.disabled ? this.tooltip.enable() : this.tooltip.disable()
    } else {
      this.tooltip.enable()
    }
  }

  get button() {
    return this.element.firstElementChild
  }

  get tippyOptions() {
    return {
      touch: ['hold', 500],
      content: this.tooltipTextValue,
      theme: this.tooltipThemeValue,
      delay: this.tooltipDelayValue
    }
  }
}
